<template>
    <div class="row">
        <div class="col-12 mb-3">
            <div class="card mb-5 mb-xl-10">
                <div class="card-header border-0 cursor-pointer">
                    <!--begin::Card title-->
                    <div class="card-title m-0">
                        <h3 v-if="!this.$route.query.id" class="fw-bolder m-0">{{ $t("pages.driveManagement.documents.addDoc") }}</h3>
                        <h3 v-else class="fw-bolder m-0">{{ $t("pages.driveManagement.documents.updateDoc") }}</h3>
                    </div>
                    <!--end::Card title-->
                </div>
                <Form id="addDocForm" :validation-schema="validateAddDoc">
                    <div class="card-body border-top p-9">
                        <!--begin::Input group-->
                        <div class="row mb-6">
                            <!--begin::Label-->
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.driveManagement.documents.docCat") }}</label>
                            <div class="col-lg-8">
                                <div class="input-group mb-5">
                                    <select name="category" as="select" class="form-select" v-model="this.document.category">
                                        <option disabled selected value="-1">{{ $t("pages.driveManagement.documents.chooseOption") }}</option>
                                        <option v-for="category in this.documentCategories" :key="category.id" :value="category.id">
                                            {{ category.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <!--begin::Label-->
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.driveManagement.documents.docTarget") }}</label>
                            <div class="col-lg-8">
                                <div class="input-group mb-5" v-for="userType in this.userTypes" :key="userType.id">
                                    <label class="form-check form-check-custom form-check-solid me-5">
                                        <input type="checkbox" :name="userType.label" :value="userType.id" v-model="this.document.user_types" class="form-check-input" />
                                        <span class="form-check-label"> {{ userType.label }} </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <!--begin::Label-->
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.driveManagement.documents.docOrder") }}</label>
                            <div class="col-lg-8">
                                <div class="input-group mb-5">
                                    <Field name="order" type="text" class="form-control" placeholder="Ordre" v-model="this.document.order" />
                                </div>
                            </div>
                        </div>

                        <div class="row mb-6">
                            <!--begin::Label-->
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.driveManagement.documents.filename") }}</label>
                            <div class="col-lg-8">
                                <div class="input-group mb-5">
                                    <span class="input-group-text" id="basic-addon1">🇫🇷</span>
                                    <Field
                                        name="label"
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t('pages.driveManagement.documents.frFileName')"
                                        v-model="this.document.label"
                                    />
                                </div>
                                <div class="input-group mb-5">
                                    <span class="input-group-text" id="basic-addon1">🇬🇧</span>
                                    <Field
                                        name="label_en"
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t('pages.driveManagement.documents.enFileName')"
                                        v-model="this.document.label_en"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-10">
                            <!--begin::Label-->
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.driveManagement.documents.file") }}</label>
                            <div class="col-lg-8 col-form-label">
                                <label class="required form-label">{{ $t("pages.driveManagement.documents.frFile") }}</label>
                                <Field name="frFile" type="file" @change="addFilesFr" class="form-control mb-6" accept="image/*, .pdf, .pptx, .txt, .xlsx, .docx"></Field>
                                <label class="form-label">{{ $t("pages.driveManagement.documents.enFile") }}</label>
                                <Field name="enFile" type="file" @change="addFilesEn" class="form-control" accept="image/*, .pdf, .pptx, .txt, .xlsx, .docx"></Field>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <!--begin::Label-->
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.driveManagement.documents.video") }}</label>
                            <div class="col-lg-8">
                                <div class="input-group mb-5">
                                    <Field
                                        name="video"
                                        as="textarea"
                                        class="form-control"
                                        :placeholder="$t('pages.driveManagement.documents.video_fr')"
                                        v-model="this.document.video"
                                    />
                                </div>
                                <div class="input-group mb-5">
                                    <Field
                                        name="video_en"
                                        as="textarea"
                                        type="textarea"
                                        class="form-control"
                                        :placeholder="$t('pages.driveManagement.documents.video_en')"
                                        v-model="this.document.video_en"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-bold fs-6">Illustration</label>
                            <div class="col-lg-8" v-if="finalIllustration === ''">
                                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_1">
                                    {{ $t("pages.driveManagement.documents.pickIllustration") }}
                                </button>
                                <span class="ms-5 fw-bold fs-4">{{ finalIllustration }}</span>
                            </div>
                            <div class="col-lg-8 col-form-label" v-else>
                                <span class="fw-bold fs-5"
                                    >{{ $t("pages.driveManagement.documents.selectedIllustration") }}
                                    <a href="#" class="" data-bs-toggle="modal" data-bs-target="#kt_modal_1">{{ finalIllustration }}</a
                                    >.
                                </span>
                            </div>
                        </div>
                        <div class="modal fade" tabindex="-1" id="kt_modal_1">
                            <div class="modal-lg modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">{{ $t("pages.driveManagement.documents.documentIllustration") }}</h5>
                                        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                            <span class="svg-icon svg-icon-2x">
                                                <i class="fas fa-times"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <div class="illustrations-container">
                                                    <img
                                                        v-for="illustration in illustrations"
                                                        v-bind:key="illustration.label"
                                                        :src="illustration.src"
                                                        :alt="illustration.label"
                                                        :class="illustration.class"
                                                        @click="selectImg(illustration)"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <p>{{ $t("pages.driveManagement.documents.pleasePick") }}</p>
                                                <p><span class="fw-bold">Illustration :</span> {{ selectedIllustration }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="modal-footer">
                                        <button v-if="finalIllustration === ''" disabled type="button" class="btn btn-primary">
                                            {{ $t("pages.driveManagement.documents.pick") }}
                                        </button>
                                        <button v-else type="button" class="btn btn-primary" data-bs-dismiss="modal">
                                            {{ $t("pages.driveManagement.documents.pick") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-end py-6 px-9">
                        <button v-if="this.$route.query.id" type="submit" @click="this.onSubmit" id="kt_account_profile_details_submit" ref="submitButton" class="btn btn-primary">
                            <span class="indicator-label"> {{ $t("general.update") }} </span>
                            <span class="indicator-progress">
                                {{ $t("general.pleaseWait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                            <span class="indicator-validate">
                                {{ $t("general.done") }}
                                <span class="fas fa-check f-5 ms-3"></span>
                            </span>
                        </button>

                        <button v-else type="submit" @click="this.onSubmit" id="kt_account_profile_details_submit" ref="submitButton" class="btn btn-primary">
                            <span class="indicator-label"> {{ $t("general.add") }} </span>
                            <span class="indicator-progress">
                                {{ $t("general.pleaseWait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                            <span class="indicator-validate">
                                {{ $t("general.done") }}
                                <span class="fas fa-check f-5 ms-3"></span>
                            </span>
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<style>
.add-file-wait {
    display: none;
}

.illustrations-container {
    max-width: 500px;
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    border: 1px solid #cacaca;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1em;
}

.illustrations-container img {
    margin: 10px;
    max-width: 120px;
    cursor: pointer;
    padding: 10px;
}

.selected {
    background-color: #31b4d538;
    border-radius: 10px;
    transition: all ease 0.3s;
}
</style>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import apiUserProfile from "@/core/services/UserProfile";
import apiDrive from "@/core/services/Drive";
import router from "@/router";
import buttonHandle from "@/shared.js";
import { useI18n } from "vue-i18n";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        if (file === undefined) {
            return resolve(null);
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        }
    });
}

export default defineComponent({
    name: "administration-add-documents",
    components: {
        Form,
        Field,
    },
    data() {
        return {
            document: {
                id: "",
                label: "",
                label_en: "",
                category: -1,
                document: "",
                document_en: "",
                video: "",
                video_en: "",
                user_types: [] as any,
                filetype: "",
                order: 1,
                picto: "infography",
            },
            illustrations: [
                {
                    label: "Planning",
                    src: "/media/illustrations/documents/planning.svg",
                    class: "",
                },
                {
                    label: "Check List",
                    src: "/media/illustrations/documents/checkList.svg",
                    class: "",
                },
                {
                    label: "Check List 2",
                    src: "/media/illustrations/documents/checkList2.svg",
                    class: "",
                },
                {
                    label: "Process",
                    src: "/media/illustrations/documents/process.svg",
                    class: "",
                },
                {
                    label: "Vidéo 1",
                    src: "/media/illustrations/documents/video.svg",
                    class: "",
                },
                {
                    label: "Vidéo 2",
                    src: "/media/illustrations/documents/video2.svg",
                    class: "",
                },
                {
                    label: "Replay Webinar",
                    src: "/media/illustrations/documents/replayWebinar.svg",
                    class: "",
                },
                {
                    label: "Tutoriel",
                    src: "/media/illustrations/documents/tutoriel.svg",
                    class: "",
                },
                {
                    label: "Appropriation résultats",
                    src: "/media/illustrations/documents/approResults.svg",
                    class: "",
                },
                {
                    label: "Appropriation résultats 2",
                    src: "/media/illustrations/documents/approResults2.svg",
                    class: "",
                },
                {
                    label: "Guide 1",
                    src: "/media/illustrations/documents/guide.svg",
                    class: "",
                },
                {
                    label: "Guide 2",
                    src: "/media/illustrations/documents/guide2.svg",
                    class: "",
                },
                {
                    label: "Guide 3",
                    src: "/media/illustrations/documents/guide3.svg",
                    class: "",
                },
                {
                    label: "Guide 4",
                    src: "/media/illustrations/documents/guide4.svg",
                    class: "",
                },
                {
                    label: "Guide anonymat",
                    src: "/media/illustrations/documents/guideAnonymat.svg",
                    class: "",
                },
                {
                    label: "Q&A 1",
                    src: "/media/illustrations/documents/QA.svg",
                    class: "",
                },
                {
                    label: "Q&A 2",
                    src: "/media/illustrations/documents/QA2.svg",
                    class: "",
                },
                {
                    label: "Guide plateforme",
                    src: "/media/illustrations/documents/guidePlateforme.svg",
                    class: "",
                },
                {
                    label: "Guide plans d'action",
                    src: "/media/illustrations/documents/guideRoadmap.svg",
                    class: "",
                },
            ],
            selectedIllustration: this.t("pages.driveManagement.documents.noIllustration"),
            finalIllustration: "",
            finalSrc: "",
            userTypes: [],
            documentCategories: [],
        };
    },
    setup() {
        const { t } = useI18n();
        const submitButton = ref<HTMLElement | null>(null);
        onMounted(() => {
            setCurrentPageBreadcrumbs(t("pages.driveManagement.documents.addDoc"), [
                {
                    link: "/administration/documents/documents",
                    label: t("pages.driveManagement.documents.title"),
                },
            ]);
        });
        return {
            t,
            submitButton,
        };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.driveManagement.documents.addDoc"), [
                {
                    link: "/administration/documents/documents",
                    label: this.t("pages.driveManagement.documents.title"),
                },
            ]);
            this.selectedIllustration = this.t("pages.driveManagement.documents.noIllustration");
        },
    },
    created() {
        this.fetchCategories();
        this.fetchUserTypes();
        if (this.$route.query.id) {
            apiDrive.getDocument(this.$route.query.id).then((data) => {
                let document = data.document;
                let pictoLbl;

                for (let i = 0; i < this.illustrations.length; i++) {
                    if (document.picto === this.illustrations[i].src) {
                        pictoLbl = this.illustrations[i].label;
                        this.selectedIllustration = pictoLbl;
                        this.illustrations[i].class = "selected";
                    }
                }
                this.document = {
                    id: document.id,
                    label: document.label,
                    label_en: document.label_en,
                    category: document.category.id,
                    filetype: document.filetype,
                    document: document.document,
                    video: document.video,
                    video_en: document.video_en,
                    document_en: document.document_en,
                    user_types: document.user_types.map((userType) => userType.id),
                    order: document.order,
                    picto: document.picto,
                };
                this.finalIllustration = pictoLbl;
            });
        }
    },
    methods: {
        onSubmit(e) {
            e.preventDefault();
            if (this.validateForm() && this.$route.query.id) {
                buttonHandle.handleWaitingButton(this.submitButton);
                apiDrive
                    .editDocument(this.document.id, this.document)
                    .then(() => {
                        buttonHandle.handleValidatedButton(this.submitButton);
                        setTimeout(() => {
                            router.push({ name: "administration-documents" });
                        }, 500);
                    })
                    .catch(() => {
                        this.displayErrorMsg("Une erreur est survenue. Contactez l'administrateur du site.");
                    });
            } else if (this.validateForm()) {
                buttonHandle.handleWaitingButton(this.submitButton);
                this.document.picto = this.finalSrc;
                if (this.document.video !== "") {
                    this.document.filetype = "video";
                }
                apiDrive
                    .addDocument(this.document)
                    .then(() => {
                        buttonHandle.handleValidatedButton(this.submitButton);
                        setTimeout(() => {
                            router.push({ name: "administration-documents" });
                        }, 500);
                    })
                    .catch(() => {
                        this.displayErrorMsg("Une erreur est survenue. Contactez l'administrateur du site.");
                    });
            }
        },
        validateForm() {
            if (this.document.category && this.document.category < 1) {
                this.displayErrorMsg(this.t("pages.driveManagement.documents.categoryValidator"));
            } else if (this.document.user_types && this.document.user_types.length === 0) {
                this.displayErrorMsg(this.t("pages.driveManagement.documents.targetValidator"));
            } else if (this.document.label === "") {
                this.displayErrorMsg(this.t("pages.driveManagement.documents.nameValidator"));
            } else if ((!this.document.document && !this.$route.query.id) && (!this.document.video && !this.$route.query.id)) {
                this.displayErrorMsg(this.t("pages.driveManagement.documents.fileValidator"));
            } else if (this.finalIllustration === "") {
                this.displayErrorMsg(this.t("pages.driveManagement.documents.illustrationValidator"));
            } else {
                return true;
            }
        },
        addFilesFr(e: any) {
            let documentFr = e.target.files[0];
            this.document.filetype = documentFr.type;
            getBase64(documentFr).then((base64Document: string) => {
                this.document.document = base64Document;
            });
        },
        addFilesEn(e: any) {
            let documentEn = e.target.files[0];
            getBase64(documentEn).then((base64Document: string) => {
                this.document.document_en = base64Document;
            });
        },
        fetchCategories() {
            apiDrive.getCategories().then((response) => {
                this.documentCategories = response.data.categories;
            });
        },
        fetchUserTypes() {
            apiUserProfile.getUserTypes().then((response) => {
                this.userTypes = response.data.usertypes;
            });
        },
        selectImg(img) {
            if (this.illustrations) {
                for (let i = 0; i < this.illustrations.length; i++) {
                    this.illustrations[i].class = "";
                }
                img.class = "selected";
                this.finalIllustration = this.selectedIllustration = img.label;
                this.document.picto = this.finalSrc = img.src;
            }
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },
});
</script>
